<template>
  <div class="d-flex flex-column justify-between">
    <div class="text--primary flex-grow-1">
      <div v-if="this.data.currency === 'bs'">
        <p class="text--primary text-center text-xl font-weight-medium">
          {{ $t("Amount to pay") }}
        </p>
        <p
          v-if="currency_rate"
          class="text--green text-center text-3xl font-weight-bold"
        >
          {{
            computedPrice
              | currency({
                symbol: "Bs ",
                thousandsSeparator: ",",
                fractionCount: 2,
                fractionSeparator: ".",
                symbolPosition: "front",
                symbolSpacing: false,
                avoidEmptyDecimals: undefined,
              })
          }}
        </p>

        <v-divider class="my-4"></v-divider>
      </div>
      <p>
        {{
          $t(
            "Make your payment using the following coordinates and instructions"
          )
        }}:
      </p>
      <v-row :class="[$vuetify.theme.isDark ? '' : 'bg-light-gray', 'my-1']">
        <v-col cols="12" class="d-flex justify-center">
          {{ data.detail }}
        </v-col>
      </v-row>
    </div>
    <!-- Botones para volver y continuar -->
    <div class="button-group">
      <v-btn
        rounded
        outlined
        style="width: 40%"
        @click="currentStep === 1 ? $emit('closePaymentDrawer') : $emit('prev')"
      >
        {{ $t("Back") }}
      </v-btn>
      <v-btn rounded style="width: 40%" color="primary" @click="handleNext">
        {{ $t("Continue") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import PhoneInput from "@/components/PhoneInput";
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: "ManualPaymentStep2",
  components: {
    PhoneInput,
    DatePickerInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currency_rate: null,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      loading: false,
    };
  },
  methods: {
    handleNext() {
      this.data.amountBs = this.computedPrice.toFixed(2);
      this.$emit("next");
    },
  },
  computed: {
    computedPrice() {
      const amount =
        typeof this.data.amount === "number"
          ? this.data.amount
          : parseFloat(this.data.amount.replace(/,/g, ""));
      return amount * this.currency_rate;
    },
  },
  async beforeMount() {
    const { data } = await axios.get("/get_currency_rate");
    if (data.success) {
      this.currency_rate = data.currency_rate;
    } else {
      throw new Error("Error getting Currency Rate");
    }
  },
};
</script>
<style scoped>
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.text--green {
  color: #2bd4aa !important;
}
.bg-light-gray {
  background-color: #fafafa !important;
}
</style>
